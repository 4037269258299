import React, { useContext, useState } from 'react';
import ConsentListContext from '../../Context/ConsentContext';
import { useTheme } from '@emotion/react';
import {
	Box,
	CardActionArea,
	Checkbox,
	Chip,
	Divider,
	ListItemIcon,
	Paper,
	Stack,
	Tooltip,
	Typography,
	useMediaQuery,
} from '@mui/material';

import sizeFontCustom from '../../Discovery/fontSize.styles';
import Field from './Field';
import {
	capitalize,
	dateFormat,
	getConsentModeText,
	getFrequencyUnit,
} from '../../../hepler/functions';
import ChipSet from './ChipSet';
import CustomPopover from '../../Popover/CustomPopover';
import AccountInfoContent from '../../Popover/AccountInfoContent';
import ConsentRequestedExpires from './ConsentRequestedExpires';
import ConsentCardLSPHeading from '../ConsentCardLSPHeading';
import PurposeContent from '../../Popover/PurposeContent';
import AuthApis from '../../../hepler/authApis.service';

const ConsentCardFull = (props) => {
	//Props
	const {
		consentDetails,
		customDetails,
		id,
		selectedConsentsState,
		handleIsActive,
		isMultiConsent,
		children,
		isLast,
	} = props;

	//Context
	const consentContext = useContext(ConsentListContext);
	const consentHandle = consentDetails?.ConsentHandle;
	const authApis = new AuthApis();
	//Priority Consents
	const priorityCodes = global?.priorityConsents?.split(',') || [];
	const isPriority = (consentDetails) => {
		return !authApis.isCheckboxRequired(consentDetails);
	};

	const isActiveFlag = isPriority(consentDetails)
		? true
		: selectedConsentsState?.includes(consentHandle) &&
		  !isPriority(consentDetails)
		? true
		: false;
	const consentList = consentContext.consentList;
	const numberOfConsents = consentList.length;

	//Themeing
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

	const [openToolTip, setOpenToolTip] = useState(false);
	const isLsp = global.institutionType === 'LSP';
	const handleClick = () => {
		setOpenToolTip((prevState) => !prevState);
	};
	return (
		<Box height={'none'} sx={{ mx: 1, mb: 1 }}>
			<Paper
				elevation={0}
				sx={{
					m: mobileScreen ? '0' : '0.25rem 0rem 0.25rem 0',
					flex: 1,
					height: '100%',

					width: mobileScreen ? '100%' : 'auto',
					backgroundColor: isActiveFlag ? theme.palette.primary.light : 'white',
				}}
			>
				<CardActionArea
					// onClick={() => console.log('click')}
					disableRipple
					sx={{
						boxShadow: numberOfConsents === 1 || isActiveFlag ? 5 : 1,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						padding: mobileScreen
							? `0.5rem 0.5rem ${isLast ? '5rem' : '1rem'} 0.5rem`
							: '1.25rem 2.75rem 1rem 1.5rem',

						borderTop: isActiveFlag
							? `5px solid ${theme.palette.primary.main}`
							: '',

						gap: mobileScreen ? '0.5rem' : '0.5rem',
						width: '100%',
						borderBottomRightRadius: mobileScreen ? 0 : 'inherit',
						borderBottomLeftRadius: mobileScreen ? 0 : 'inherit',
					}}
				>
					<Box
						width={'100%'}
						onClick={
							isMultiConsent && !isPriority(consentDetails)
								? () => handleIsActive(consentDetails.ConsentHandle)
								: null
						}
					>
						{isLsp && (
							<>
								<ConsentCardLSPHeading
									isActiveFlag={isActiveFlag}
									consentDetails={consentDetails}
								/>
								<Divider variant="light" flexItem sx={{ mb: 1 }} />
							</>
						)}

						<Stack
							justifyContent={'space-between'}
							gap={mobileScreen ? '1rem' : '1.625rem'}
							width={'100%'}
						>
							<Box
								display={'flex'}
								justifyContent={'space-between'}
								alignItems={'center'}
							>
								{/* Number */}
								<Box
									display={'flex'}
									flexDirection={'row'}
									width="100%"
									height={isMultiConsent && mobileScreen ? '100%' : 'auto'}
									alignItems={'center'}
								>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											pl: mobileScreen
												? '0.25rem'
												: isLsp
												? '0.25rem'
												: '0.5rem',
											flexGrow: 1,
											maxHeight: '100%',
										}}
									>
										<div>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'space-between',
												}}
											>
												<div
													style={{
														display: 'flex',
														alignItems: 'center',
														gap: 4,
													}}
												>
													{numberOfConsents > 1 && !isLsp ? (
														<Chip
															variant="outlined"
															size="small"
															label={`${id} of ${numberOfConsents} `}
															sx={{
																// mr: 0.5,
																// mt: 0.5,
																border: '1px solid #81858F',
																fontWeight: 700,
																color: '#81858F',
																'& .MuiChip-label': {
																	fontSize: '0.675rem',
																},
															}}
														/>
													) : null}
													<Typography
														sx={{
															...sizeFontCustom.subHeading,
															fontFamily: customDetails?.fontFamily,
															textAlign: 'left',
														}}
													>
														Consent Purpose
													</Typography>
													<CustomPopover
														isActiveFlag={isActiveFlag}
														content={
															<PurposeContent
																code={consentDetails?.Purpose?.code}
																category={
																	consentDetails?.Purpose?.Category?.type
																}
															/>
														}
													/>
												</div>
												{numberOfConsents > 1 &&
												!isLsp &&
												!isPriority(consentDetails) ? (
													<Checkbox
														color={'primary'}
														checked={isActiveFlag}
														key={Math.random()}
														name={consentDetails?.ConsentHandle}
													/>
												) : null}
											</div>
										</div>
										<Typography
											sx={{
												...sizeFontCustom.field,
												// maxWidth: mobileScreen ? '60vw' : '19vw',

												fontFamily: customDetails?.fontFamily,
												textAlign: 'left',
												fontWeight: 700,
											}}
										>
											{consentDetails?.Purpose?.text}
										</Typography>
									</Box>
								</Box>
							</Box>
						</Stack>
					</Box>
					<Box sx={{ width: '100%' }}>
						{!isLsp && <Divider variant="light" flexItem sx={{ mb: 1 }} />}
						<Stack
							component="ul"
							sx={{
								px: '0.25rem',
								mx: '0rem',
								mt: '0rem',
								width: '100%',
								gap: mobileScreen ? '0rem' : '0.2rem',
							}}
						>
							<div
								style={{
									width: '100%',
									display: 'grid',
									gridTemplateColumns: `${
										mobileScreen ? 'repeat(2, 1fr)' : 'repeat(6, 1fr)'
									}`,
									columnGap: '2rem',
									margin: '0 0 0.4rem 0',
								}}
							>
								<Field
									property="Data fetch frequency"
									value={
										consentDetails?.fetchType === 'ONETIME'
											? 'ONE TIME'
											: `${
													consentDetails?.Frequency?.value
											  } times ${getFrequencyUnit(
													consentDetails?.Frequency?.unit
											  )}`
									}
									customDetails={customDetails}
								/>
								<Field
									property={`Data Use`}
									value={getConsentModeText(
										consentDetails?.mode,
										consentDetails?.DataLife?.value,
										consentDetails?.DataLife?.unit
									)}
									customDetails={customDetails}
								/>
								<Field
									property="Data fetch from"
									value={dateFormat(consentDetails?.DataDateTimeRange.from)}
									customDetails={customDetails}
								/>
								<Field
									property="Data fetch until"
									value={dateFormat(consentDetails?.DataDateTimeRange?.to)}
									customDetails={customDetails}
								/>
								{!mobileScreen && <ConsentRequestedExpires {...props} />}
							</div>
							{mobileScreen && (
								<div
									style={{
										display: 'grid',
										gridTemplateColumns: '1fr 1fr',
										columnGap: '2rem',
										margin: '0 0 0.4rem 0',
									}}
								>
									{mobileScreen && <ConsentRequestedExpires {...props} />}
								</div>
							)}
						</Stack>
					</Box>
					<Divider variant="light" flexItem sx={{ mt: '-0.75rem' }} />
					<div
						style={{
							display: 'grid',
							gridTemplateColumns: mobileScreen ? '' : '1fr 1fr',
						}}
					>
						<Field
							property="Account Information"
							customDetails={customDetails}
							popoverComponent={
								<CustomPopover
									isActiveFlag={isActiveFlag}
									content={<AccountInfoContent />}
								/>
							}
							tooltipText={true}
						>
							<div
								style={{
									display: 'flex',
									flexWrap: 'wrap',
									gap: 1,
									margin: '0 0 0.4rem 0',
								}}
							>
								{consentDetails?.consentTypes.map((type) => (
									<Chip
										key={type}
										variant="outlined"
										size="small"
										label={capitalize(type)}
										sx={{
											mr: 0.5,
											mt: 0.5,
											color: '#81858F',
											fontSize: '0.675rem',
										}}
									/>
								))}
							</div>
						</Field>
						<Field
							property="Account Types Requested"
							customDetails={customDetails}
							styles={{ width: '100%' }}
						>
							<ChipSet fiTypes={consentDetails?.fiTypes} />
						</Field>
					</div>
					<div className="lastChildCard" />
					<div style={{ width: '100%' }}>{children}</div>
				</CardActionArea>
			</Paper>
		</Box>
	);
};

export default ConsentCardFull;
