import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import LinkedAccountsFI from './LinkedAccountsFI';
import linkedAccountsSectionStyles from './LinkedAccountsSection.styles';
import sizeFontCustom from '../Discovery/fontSize.styles';

function LinkedAccountsSection(props) {
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
	const [checkedItems, setCheckedItems] = useState({});

	global.FIGroups = {
		'Bank Accounts': ['DEPOSIT'],
		'Deposits': ['TERM-DEPOSIT', 'RECURRING_DEPOSIT'],
		'Insurance': ['INSURANCE_POLICIES'],
		'General Insurance': ['GENERAL_INSURANCE'],
		'Life Insurance': ['LIFE_INSURANCE'],
		'Mutual Funds': ['MUTUAL_FUNDS'],
		'Equities': ['EQUITIES'],
		'GSTIN': ['GSTR1_3B'],
		'NPS': ['NPS'],
		'ETF': ['ETF'],
		'REIT': ['REIT'],
		'INVIT': ['INVIT'],
		'IDR': ['IDR'],
		'CIS': ['CIS'],
		'AIF': ['AIF'],
		'SIP': ['SIP'],
	};

	let fiForConsent = [];
	let fiWithLinkedAccounts = {};
	let fiGroupsWithZeroAccts = [];
	const allConsentsFI = new Set();
	let consents = [props?.consent];

	consents.forEach((el) =>
		el?.fiTypes.forEach((fiType) => allConsentsFI.add(fiType))
	);
	fiForConsent = Object.keys(global.FIGroups).filter((key) =>
		global.FIGroups?.[key].some((fi) => allConsentsFI.has(fi))
	);
	if (fiForConsent.length > 1) {
		fiForConsent.sort((fiGroup1, fiGroup2) => {
			const noOfAccounts1 = props.linkedAccounts?.filter((account) =>
				global.FIGroups?.[fiGroup1].includes(account?.FIType)
			)?.length;
			const noOfAccounts2 = props.linkedAccounts?.filter((account) =>
				global.FIGroups?.[fiGroup2].includes(account?.FIType)
			)?.length;
			fiWithLinkedAccounts[fiGroup1] = noOfAccounts1;
			fiWithLinkedAccounts[fiGroup2] = noOfAccounts2;

			if (!noOfAccounts1 && !fiGroupsWithZeroAccts.includes(fiGroup1))
				fiGroupsWithZeroAccts.push(fiGroup1);
			if (!noOfAccounts2 && !fiGroupsWithZeroAccts.includes(fiGroup2))
				fiGroupsWithZeroAccts.push(fiGroup2);

			if (noOfAccounts1 === noOfAccounts2)
				return fiGroup1.localeCompare(fiGroup2);
			return noOfAccounts2 - noOfAccounts1;
		});
	} else if (fiForConsent.length === 1) {
		const noOfAccounts = props.linkedAccounts.filter((account) =>
			global.FIGroups?.[fiForConsent[0]].includes(account?.FIType)
		)?.length;
		fiWithLinkedAccounts[fiForConsent[0]] = noOfAccounts;
		if (!noOfAccounts) fiGroupsWithZeroAccts.push(fiForConsent[0]);
	}
	let titleSize = sizeFontCustom.titleMainFiu;

	useEffect(() => {
		let checkAll = {};
		checkAll = props.linkedAccounts?.reduce((accumulator, obj) => {
			accumulator[obj.accRefNumber] = true;
			return accumulator;
		}, {});
		setCheckedItems(checkAll);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCheckboxChange = (event, acc) => {
		// console.log(acc, "clicked")
		if (props?.consent) {
			props.handleSelect(acc, props?.consent?.ConsentHandle);
		} else {
			props.handleSelect(acc);
		}
		const { name, checked } = event.target;
		setCheckedItems((prevCheckedItems) => ({
			...prevCheckedItems,
			[name]: checked,
		}));
	};

	return (
		<Box
			sx={{
				padding: mobileScreen ? ' 0.5rem' : '0',
				backgroundColor: 'inherit',
				gap: mobileScreen ? '1rem' : '1.5rem',
				...linkedAccountsSectionStyles.container,
				minHeight: 'auto',
			}}
		>
			<Box
				sx={{
					width: '100%',
					margin: {
						xs: '0 0 0.25rem 0rem',
						md: '1rem 0 0 0',
					},
				}}
			>
				<Box width={'100%'}>
					<Box
						sx={{
							width: '100%',
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: mobileScreen ? 'flex-start' : 'center',
							flexDirection: mobileScreen ? 'column-reverse' : 'row',
						}}
					>
						<Typography
							sx={{ ...linkedAccountsSectionStyles.title, ...titleSize }}
						>
							Select Accounts
						</Typography>
					</Box>
					<Typography
						color={theme.palette.black60}
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							textAlign: 'start',
							...sizeFontCustom.linkAccountsText,
						}}
					>
						Link and allow access from your accounts.{' '}
					</Typography>

					{global?.alternativeText?.accountSelectionNote && (
						<Typography
							sx={{
								display: 'flex',
								color: 'grey',
								justifyContent: 'space-between',
								textAlign: 'start',
								...sizeFontCustom.linkAccountsText,
							}}
						>
							{global?.alternativeText?.accountSelectionNote}
						</Typography>
					)}
				</Box>
			</Box>
			{/* fiGroups with > 0 linked accounts */}
			{fiForConsent.map((key) =>
				fiWithLinkedAccounts?.[key] > 0 ? (
					<LinkedAccountsFI
						key={key}
						name={key}
						checkedItems={checkedItems}
						handleCheckboxChange={handleCheckboxChange}
						requiredFI={fiForConsent}
						{...props}
					/>
				) : null
			)}
			{/* fiGroups with 0 linked accounts */}
			{fiGroupsWithZeroAccts.length ? (
				<LinkedAccountsFI
					name={'Add Other Accounts'}
					checkedItems={checkedItems}
					requiredFI={fiForConsent}
					fIGroupsWithZeroAccts={fiGroupsWithZeroAccts}
					{...props}
				/>
			) : null}
		</Box>
	);
}

export default LinkedAccountsSection;
