import { AppBar, Box, Button, IconButton, Toolbar } from '@mui/material';
import headerStyles from './Header.styles';
import { useLocation } from 'react-router-dom';
import finvu from '../../assets/finvu.png';
import CloseIcon from "@mui/icons-material/Close";
import AuthApis from '../../hepler/authApis.service';
import DialogBox from '../Dialog Box/Dialog Box';
import CancelConsent from '../Accounts/CancelConsent';
import { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

function Header(props) {
	const authApis = new AuthApis();
	const [openDialog, setOpenDialog] = useState(false);
	const history = useHistory();
	const handleCancel = () => {
		setOpenDialog(false);
		history.push({
			pathname: `/consent-status`,
			state: {
				status: 'Consent denied',
				statusCode: authApis.handleCancelTermination("cancel"),
			},
		});
	}
	const location = useLocation();
	const shouldShowIcon = location.pathname.includes('home') || location.pathname.includes('accounts');
	const handleClose = (event, reason) => {
		setOpenDialog(false);
	};
	return (
		<Box sx={headerStyles.container}>
			<AppBar sx={headerStyles.appBar}>
				<Toolbar sx={headerStyles.p0}>
					<Box sx={headerStyles.imageBox}>
						{props.customDetails?.logo ? (
							<Box sx={headerStyles.logoContainer}>
								<img
									src={props.customDetails?.logo}
									alt="logo"
									style={headerStyles.logo}
								/>
							</Box>
						) : null}
						{shouldShowIcon && (
								<IconButton
										onClick={() => setOpenDialog(true)}
										disableRipple 
										sx={headerStyles.cancelIcon}
									>
										<CloseIcon sx={headerStyles.cancelIconFont} />
								</IconButton>
							)}
					</Box>
				</Toolbar>
			</AppBar>
			<DialogBox
				open={openDialog}
				handleClose={handleClose}
				title="Cancel Consent"
				subTitle="Are you sure you want to exit the Account Aggregator Journey."
				boxPadding="1.5rem"
				subTitleMaxWidth="85%"
			>
				<CancelConsent
					handleCancel={handleClose}
					{...props}
					handleDeny={handleCancel}
				/>
			</DialogBox>
		</Box>
	);
}

export default Header;
