const abbreviationMap = {
	SBI: 'State Bank of India',
	PNB: 'Punjab National Bank',
	BOB: 'Bank of Baroda',
	PSB: 'Punjab and Sind Bank',
    NSDL: 'National Securities Depository Limited',
    CDSL: 'Central Depository Services Limited',
	IOB: 'Indian Oversease Bank',
	HSBC: 'The Hongkong and Shanghai Banking Corporation Limited',
	GSTIN: 'Goods and Services Tax Network',
	SIB: 'The South Indian Bank',
	IB: 'Indian Bank',
	BOI: 'Bank of India',
	CBI: 'Central Bank of India',
	UBI: 'Union Bank of India',
	YBL: 'Yes Bank Ltd'
	// Add more as needed
};

export default abbreviationMap;