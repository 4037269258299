export const isAccountLinked = (discoveredAccount, linkedAccounts) => {
	const { FIType, accType, maskedAccNumber } = discoveredAccount;
	return linkedAccounts.some(
		(linkedAccount) =>
			linkedAccount.FIType === FIType &&
			linkedAccount.accType === accType &&
			linkedAccount.maskedAccNumber === maskedAccNumber
	);
};

//Filtering Accounts based on FIType and AccType
export const filterAccountsByRestrictions = (accounts) => {
	const accTypes = Array.isArray(global?.accountRestrictions?.accType)
		? global?.accountRestrictions?.accType.map((type) => type.trim())
		: global?.accountRestrictions?.accType
		? [global?.accountRestrictions?.accType.trim()]
		: [];

	const fiTypes = Array.isArray(global?.accountRestrictions?.FIType)
		? global?.accountRestrictions?.FIType.map((type) => type.trim())
		: global?.accountRestrictions?.FIType
		? [global?.accountRestrictions?.FIType.trim()]
		: [];

	return accounts.filter(
		(acc) => accTypes.includes(acc.accType) && fiTypes.includes(acc.FIType)
	);
};
