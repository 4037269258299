import posthog from 'posthog-js';

export const trackPostHogEvent = (eventName, properties = {}) => {
	try {
		// Add common properties that should be included with all events
		const commonProperties = {
			entityId: global?.entityName,
			timestamp: new Date().toISOString(),
		};

		posthog.capture(eventName, {
			...commonProperties,
			...properties,
		});
	} catch (e) {
		console.error('PostHog tracking failed:', e);
	}
};
